// Here you can add other styles
.sideNav-background {
    background: rgb(0, 126, 229);
}

.image-style {
    width: '10%',
}

.d-md-flex {
    display: flex;
}

.breadcrumb-item active {
    color: aqua;
}

.shaddow {
    box-shadow: 1px 1px 1px red;
}

button:focus {
    outline: none;
}

dl {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

dt {
    float: left;
    margin: 0
}

dd {
    float: left;
    width: 50%;
}
.cardLayout {
    border: 1px solid;
    border-radius: 20px;
    height: 207px;
    padding: 0px 20px;
    background-color: #fff;
    box-shadow: "5px 10px red"
}

.cardTitle {
    font-size: 26px;
    height: 54px;
    border-bottom: 1px solid #EBF2F7;
    display: flex;
    align-items: center;

}

.secondaryTextCard {
    padding: 10px 0;
    font-size: 20px;
    color: #97989A
}

.border-color {
    border: 2px solid #007EFF
    // box-shadow: '5px 5px 5px rgb(0,126,229)';
}

.border-Itinerary {
    border-color: #9125D6
}

.border-Reviews {
    border-color: #D6A725
}

.border-Mail {
    border-color: #C9661A
}
.loaderStyle {
    color: rgb(0, 126, 229);

}

fieldset{
    top: 0!important;
}
.MuiFormControl-root {
    &:has(div):has(fieldset):has(.css-14lo706){
        label{
            background-color: white!important;
            padding: 0 5px;
        }
    }
}

.MuiFormControl-root {
    &:has(div):has(fieldset):has(.css-ihdtdm){
        label{
            background-color: white!important;
            padding: 0 5px;
        }
    }
}

.cursor-pointer{
    cursor: pointer;
}

.DateInput__small {
    width: 110px !important;
}
.DateRangePickerInput__withBorder {
    border-radius: 10px !important;
}
.DateRangePickerInput_clearDates{
    display: flex;
}
