.button-animation {
  display: inline-block;
  border-radius: 20px;
  margin-left: 5px;
  border: 2px solid white;
  border-left-color: rgba(255, 255, 255, 0);
  height: 15px;
  width: 15px;
  font-size: 0;
  animation: button-animation-rotating 1s  linear infinite;
  transition: all 0.3s ease-in-out;
}
@keyframes button-animation-rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}